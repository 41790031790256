import React from 'react'
import NavBar from '../components/Navbar/NavBar'
import {Footer2} from '../components/Footer2'
import Heading from '../components/Navbar/Heading';
import img from '../images/Analytics.svg';
import img1 from '../images/f.svg';
import img2 from '../images/e.svg';
import img3 from '../images/d.svg';
import img4 from '../images/b.svg';
import img5 from '../images/a.svg';
import img6 from '../images/n.svg';
import BookCallModal from '../components/Model/BookCallModal';

const About = () => {
    const [isOpen, setisOpen] = React.useState(false);
  const   vsiondata=[{
        img:img1,
        title:'Innovation',
    },
    {
        img:img2,
        title:'Trust',
    },
    {
        img:img4,
        title:'Excellence',
    },
    {
        img:img3,
        title:'Accountability',
    },
    {
        img:img6,
        title:'Customer Centric',
    },
    {
        img:img5,
        title:'Ethical Conduct',
    }]
  return (
    <>
 
<div className='flex justify-between flex-col h-full'>
<NavBar />

<div className="flex flex-col items-center justify-center ">
{/* <Heading html='ABOUT US'  before="ABOUT US"/> */}

<div className="flex flex-col-reverse pt-28 lg:flex-row items-center justify-between lg:text-left" data-aos="fade-up">
                       
                       <div className="flex-col my-4 pt-8 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                           
                           <h3 className="text-4xl  text-white font-bold">We are a service provider software house</h3>
                           <div>
                               <p className='my-3 text-2xl rounded-2xl text-justify text-white font-semibold'>We are creating a milestone in the world of technology with our experts. Our clients get assured services by our technology experts and we get A+ client feedback. We have trained and developed technology personnel who will quench your thirst for digital solutions</p>
                               <div onClick={()=>setisOpen(true)}  className="text-white bg-[#558b97] hover:bg-[#306975] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                  book a Call
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </div>
                           </div>
<div className='flex flex-wrap  '>
                         
                           </div>
                     
                           
                           
                       
                          
                       </div>
                       <div className="lg:w-1/2 flex flex-col lg:m-4 justify-center">
                           <img alt="card img" className="rounded-t " src={img} />
                       </div>
                   </div>
                   <Heading html='VISION'  before="VISION
"/>
<div className='px-8 flex item-center flex-col-reverse lg:flex-row justify-center pb-16 '>
<p className='w-full lg:w-1/2 text-xl flex justify-center items-center text-justify text-white'>At Devdecorators, whether you're in need of a robust full-stack development solution or a seamless mobile app experience, our unwavering commitment to decentralization is the cornerstone of our services. We strive to equip our clients with the essential tools to navigate the decentralized landscape, ushering in a new era characterized by transparency, security, and individual empowerment in the digital realm. Join us on this transformative journey toward a decentralized future,
     where you have full command of your digital presence.</p>
     <div className='grid lg:grid-cols-3 grid-cols-2 gap-4 grid-row-2 w-full lg:w-1/2 '>
    {vsiondata.map((v,i)=>
    <div className='flex flex-col jus items-center space-y-2'>
<img alt="card img" className="" src={`${v.img}`} />
<p className='text-xl  text-white'>{v.title}</p>
</div>
)}

</div>
</div>
</div>

<Footer2  />
</div>
<BookCallModal isOpen={isOpen} setisOpen={setisOpen} />
</>
  )
}

export default About