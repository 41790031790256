import React from 'react'
import { HeartIcon } from '@heroicons/react/24/outline'
// import { div } from '../twin/Twin'
import {portfolioConstant } from '../constant/Constant'
import PortfolioModal from '../Model/PortfolioModal'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 560 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 560, min: 320 },
    items: 1
  }
};
 const Products = () => {
    const [isOpen, setIsModal] = React.useState(false)
    const [ModalData, setModalData] = React.useState()
  return (
    <>
 {/* <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
          {portfolioConstant.map((v, index) => (
            <div
              onClick={() => {
                setIsModal(true)
                setModalData(v)
              }}
              key={index}
              className="flex p-6 bg-gray-500 group rounded-lg hover:bg-[#554256] flex-col w-full h-[450px] group "
            >
              <div className=" w-full overflow-hidden rounded-[6px]">
                <img
                  src={v.img}
                  alt=""
                  className="rounded-[6px] w-full h-[300px]  overflow-hidden group-hover:scale-110 transform origin-center transition-transform duration-500 cursor-pointer"
                />
              </div>
              <div className="flex justify-between pt-3">
                <p className="text-white font-semibold text-2xl ">{v.titleCard}</p>
                <div className="flex space-x-2">
                 
                </div>
              </div>

              <div className="font-semibold text-lg   text-gray-300 text-justify pt-3 font-Poppins cursor-pointer   flex">
             <p>  {v.subCard.length > 80
                                      ? `${v?.subCard.substring(0, 80)}...`
                                      : `${v?.subCard}`} </p>
              </div>
              <p className=' text-[#558b97] font-bold  group-hover:text-[#f9004d] group-hover:underline underline-[#f9004d]'>Learn more</p>
            </div>
          ))}
        </div> */}
         <div className="" data-aos="fade-down" data-aos-delay="600">
                        <div className="justify-center w-[100%]">
                        {/* onClick={() => {
                              setIsModal(true)
                              setModalData(v)
                            }} */}
                            <Carousel 
                            responsive={responsive}>


                        {portfolioConstant.map((v, index) => (
                            <div  className="mx-3 hover:bg-[#554256] opacity-3 transition-all ease-in-out duration-400 h-[100%]  overflow-hidden text-white bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify flex justify-center items-center flex-col text-sm">
                                <img alt="card img" className="rounded-t aspect-auto object-cover object-center w-full  group-hover:scale-[1.05] h-[200px]  transition duration-1000 ease-in-out"  src={v.img} />
                                    <h2 className="font-semibold my-4 text-2xl   text-center">{v.titleCard}</h2>
                                    <p className="text-md font-medium pb-5">
                                     {v.subCard.length > 180
                                      ? `${v?.subCard.substring(0, 180)}...`
                                      : `${v?.subCard}`} 
                                    </p>
                                </div>
                            </div>  ))}
                            </Carousel>
                            </div>
                            </div>
         <PortfolioModal
         setIsModal={setIsModal}
         isOpen={isOpen}
         ModalData={ModalData}
       />
       </>
  )
}

export default Products