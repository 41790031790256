import React from 'react'
import { Link } from 'react-router-dom'
import img from '../images/value.svg';
import Heading from './Navbar/Heading';
const Value = () => {
    const wordsArray = [
        'Innovation',
        'Agility',
        'Collaboration',
        'Communication',
        'Excellence',
        'Adaptability',
        'Quality',
        'Precision',
        'Focus',
        'Iteration',
        'Continuous',
        'Integrity',
        'Satisfaction',
        'Efficiency',
        'Resilience',
      ];
  return (
    <section data-aos="zoom-in-down" className='container mx-auto'>
                    <div className="my-4 pt-4">
                        {/* <h2 className="my-2 text-center text-3xl text-white uppercase font-bold">VALUES</h2> */}
                        <Heading html='VALUES'  before="VALUES"/>
                        {/* <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-teal-900'></div>
                        </div> */}
                        {/* <h2 className="mt-4 mx-12 text-center text-5xl  font-bold text-gray-600 ">What we <span className='text-white'>Value</span></h2> */}
                    </div>
    <div className=" pb-2 px-4  " id='about' >

                    <div className="flex flex-col-reverse lg:flex-row  justify-between lg:text-left" data-aos="fade-up">
                       
                        <div className="flex-col my-4 pt-8 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-4xl  text-white font-bold">How we work</h3>
                            <div>
                                <p className='my-3 text-2xl text-white font-semibold'>You’ll be working with a small, dedicated team built to fit the needs of your project. We use tools like Jira, Slack, Zoom and GitHub to communicate frequently.</p>
                            </div>
<div className='flex flex-wrap  '>
                            {wordsArray.map((v)=>
                   <button className='px-2 my-1 mr-3 py-1 rounded hover:bg-white border hover:text-teal-900 bg-[#558b97] text-white'>{v}</button>
                            )}
                            </div>
                      
                            
                            
                        
                            {/* <Link to="/contact" className="text-white bg-teal-900 hover:bg-teal-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Contact us
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link> */}
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={img} />
                        </div>
                    </div>
                </div>
                </section>
        
  )
}

export default Value