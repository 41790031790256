import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/hero.jpeg';
import ParticlesContainer from './ParticlesContainer';
import BookCallModal from './Model/BookCallModal';

const Hero = () => {
    const [isOpen, setisOpen] = React.useState(false);
    return (
        <>
        <ParticlesContainer />
            <div >
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto  overflow-hidden flex items-center w-full justify-center  pt-28 lg:mt-4 p-2 md:px-12   " >

                    <div className="flex flex-col lg:flex-row pt-24 items-center justify-center text-center  lg:text-left">
                        <div className=" flex flex-col item-center text-center   justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <div className='flex w-full justify-center items-center'>
                            <h1 className="mb-5 md:w-2/3 text-center flex items-center md:text-5xl text-3xl font-bold text-white">
                            {/* We build digital solutions to help businesses scale */}
                                Bespoke software solutions for your unique business needs
                            </h1>
                            </div>
                            <div className="text-xl font-semibold tracking-tight mb-5 text-white">We are a team of highly motivated and skilled developers dedicated to delivering only the best software.</div>
                            <div className=" space-x-0 md:space-x-2 mb-4">
                            <div onClick={()=>setisOpen(true)}  className="text-white bg-[#558b97] hover:bg-[#306975] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                  book a Call
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </div>
                                <Link to="/about" className=" border text-white hover:bg-[#306975] hover:text-white inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Who We are
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link>
                              
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
            <BookCallModal isOpen={isOpen} setisOpen={setisOpen} />
        </>
    )
}

export default Hero;