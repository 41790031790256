import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from '../Navbar/NavLinks';
import { Transition } from '@headlessui/react';
import logo from '../../images/logo2.png';
const NavBar = () => {
  const [top, setTop] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const dropdownRef = useRef(null);
  const [isOpen, setisOpen] = useState(false);

  function handleClick() {
    setisOpen(!isOpen);
  }

  useEffect(() => {
    const scrollHandler = () => {
      const currentScrollPos = window.scrollY;
      setTop(prevScrollPos > currentScrollPos || currentScrollPos === 0);
      setPrevScrollPos(currentScrollPos);
      setisOpen(false);
    };

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [prevScrollPos]);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setisOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <nav
      className={`w-full ${top ? 'fixed backgroundgradient z-30 top-0 ' : ''} transition duration-300 ease-in-out   text-white ${
        top ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div className="flex flex-row justify-between items-center py-2">
        <div className="flex flex-row justify-center pl-8 items-center text-center font-semibold">
          <Link to="/">
            {/* <h1 className="font-extrabold text-4xl text-white">Dev Decorators</h1> */}
               <img src={logo} alt='lgo' className='w-[200px]' />
          </Link>
        </div>
        <div className="group flex flex-col items-center">
          <button className="p-2 rounded-lg lg:hidden text-white" onClick={handleClick}>
          <button className="p-2 rounded-lg lg:hidden text-white" onClick={handleClick}>
                        <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {isOpen && (
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                            )}
                            {!isOpen && (
                            <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                            )}
                        </svg>
                    </button>
          </button>
          <div className="hidden space-x-6 lg:inline-block p-5">
            <NavLinks />
          </div>
          <Transition
            onClose={isOpen}
            show={isOpen}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="dropdown max-h-0 overflow-hidden"
          >
            <div
              ref={dropdownRef}
              className={`fixed transition-transform duration-300 ease-in-out transit flex flex-col top-0 left-0 w-full h-auto backgroundgradient rounded-md p-8 rounded-lg block lg:hidden shadow-xl  `}
            >
              <button className="p-2 flex w-full justify-end items-end w-full rounded-lg lg:hidden text-white" onClick={handleClick}>
           
                        <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {isOpen && (
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                            )}
                            {!isOpen && (
                            <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                            )}
                        </svg>
                  
              </button>
              <div className="flex flex-col justify-center items-center w-full space-y-6">
                <NavLinks />
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
