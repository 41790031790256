import React from 'react';
import { Link } from 'react-router-dom';
import Heading from './Navbar/Heading';
import pro1 from '../images/pro1.png';
import Products from './product/Products';
const Portfolio = () => {
    return (
        <>
            <div className="mt-8 container mx-auto" >
              
                <Heading html='PROJECTS'  before="PROJECTS"/>
                {/* <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-teal-900 mb-8'></div>
                </div> */}

               <Products />
            </div>
        </>
    )
}

export default Portfolio;