import React from 'react'

const Heading = ({html,before}) => {
    const beforeStyle = 'before:content-[attr(title)] before:font-rubik before:opacity-[0.09] before:text-[60px] before:lg:text-[124px]  before:bg-[linear-gradient(180deg,#1a1a1a,#eef3fc)] before:bg-clip-text  before:absolute before:left-[50%] before:top-[50%] before:leading-[.9] before:whitespace-nowrap  before:translate-x-[-50%] before:translate-y-[-50%] border-0 border-solid overflow-hidden lg:overflow-visible'
  return (
    <div className="py-12  w-full ">
    <div className="  text-center">
      <div className="m-2 md:m-12">
        <h2
          className={`font-bold mb-0 relative text-[30px] md:text-[35px] lg:text-[40px]  leading-tight text-white ${beforeStyle} `}
          title={before}
        >
          {html}
        </h2>

      </div>
    </div>
  </div>
  )
}

export default Heading