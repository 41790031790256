import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
        <a className="px-4 block lg:hidden font-extrabold text-lg text-white " href="/">
                Home
            </a>
        <a className="px-4 font-extrabold text-lg text-white "  href="/#services">
                Services
            </a>
            <a className="px-4 font-extrabold text-lg text-white " href="/#products">
                Projects
            </a>
            <a className="px-4 font-extrabold text-lg text-white " href="/#values">
                Values
            </a>
            
            
            <a className="px-4 font-extrabold text-lg text-white " href="/contact">
                Contact Us
            </a>
            {/* <Link className="text-white bg-[#558b97] text-lg hover:bg-[#306975] inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo">
            Portfolio
            </Link> */}
        </>
    )
}

export default NavLinks;