import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import {Footer2} from '../components/Footer2';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import Value from '../components/Value';


const Home = () => {
    return (
        <>
            <Hero />
           <div  id="services" />
            <Services />
            <div id='products' />
            <Portfolio />
            
            {/* <Clients /> */}
          <div id='values' />
            <Value />
            {/* <Intro /> */}
            <Cta/>
            <Footer2 />
            {/* <Footer /> */}
        </>

    )
}

export default Home;

