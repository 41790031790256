
import img1 from '../../images/f.svg';

export const vsiondata=[{
    img:img1,
    title:'Innovation',
},
{
    img:'../../images/f.svg',
    title:'Trust',
},
{
    img:'../../images/f.svg',
    title:'Excellence',
},
{
    img:'../../images/f.svg',
    title:'Accountability',
},
{
    img:'../../images/f.svg',
    title:'Customer Centric',
},
{
    img:'../../images/f.svg',
    title:'Ethical Conduct',
}]


export const portfolioConstant = [
    {
      titleCard: 'Music Store',
      subCard: 'A simple Music Store app to buy or rent music audio/videos. It has a landing site for guests, Users can sign up and attach their payment information to buy items. ',
      titleModal: 'Music Store',
      subModal:
        'A simple Music Store app to buy or rent music audio/videos. It has a landing site for guests, Users can sign up and attach their payment information to buy items.',
      subModal2:
        't also has a Backend Admin Panel to manage everything. Built on Laravel 9, React, Paypal, Mysql, HTML, and CSS.',
      img: '/images/pro1.png',
      images:[{img:"/images/pro1.png",title:"HOME"},{img:"/images/pro011.png",title:"SIGN-UP"},{img:"/images/pro0112.png",title:"ALBUMS"},{img:"/images/pro0113.png",title:"VIDEO"},{img:"/images/pro0114.png",title:"PAYMENT"},{img:"/images/pro0115.png",title:"PAYMENT VIA DEBIT CARD"},{img:"/images/pro0116.png",title:"CONTACT US"},{img:"/images/pro0117.png",title:"STORE"}],
      link: '',
      skill:["Laravel",
        "React",
        "Paypal",
        "MySQL",
        "HTML",
        "CSS"],
    },
    {
      titleCard: 'Gestr.uk',
      subCard: 'The Gestr platform was created to build communities and networks. Our platform makes planning and organizing in a group and enabling charitable work and volunteering simply.The platform was built on Laravel 8, Laravel Breeze, Mysql 8, and Calendar JS.',
      titleModal: 'Gestr.uk',
      subModal:
        'The Gestr platform was created to build communities and networks. Our platform makes planning and organizing in a group and enabling charitable work and volunteering simply. The platform was built on Laravel 8, Laravel Breeze, Mysql 8, and Calendar JS..',
      subModal2:
        '',
        img: '/images/pro2.png',
        images: [
          { img: "/images/g1.png", title: "HOME" },
          { img: "/images/g2.png", title: "DASHBOARD" },
          { img: "/images/g3.png", title: "QUICK TIPS" },
          { img: "/images/g4.png", title: "INFORMATION" },
          { img: "/images/g5.png", title: "CALENDAR VIEW -1" },
          { img: "/images/g6.png", title: "CALENDAR VIEW -2" },
          { img: "/images/g7.png", title: "INVITE" },
          { img: "/images/g8.png", title: "INVITE OTHERS" },
          { img: "/images/g9.png", title: "MEMBERSHIP PLAN" },
          { img: "/images/g10.png", title: "UPDATE" },
          { img: "/images/g11.png", title: "STORE" },
          { img: "/images/g12.png", title: "CONFIRMATION" }
        ]
        ,
        skill:["Laravel",
         " Breeze",
         " MySQL",
          "JavaScript"],
      link: 'https://gestr.co.uk/'
    },
    {
      titleCard: 'Ozbon.ng',
      subCard: 'Implement AdSense on the project and set where to show ads.There were many bugs in the Vue code, also resolve them. ',
      titleModal: 'Ozbon.ng',
      subModal:
        'Implement AdSense on the project and set where to show ads.There were many bugs in the Vue code, also resolve them.',
      subModal2:
        '',
        images:[{img:"/images/pro3.png",title:"HOMEPAGE"},],
        img: '/images/pro3.png',
        skill:["CodeIgniter",
          "Vue.js",
        "  PHP",
         " MySQL",
          "HTML",
          "JavaScript",
          "Bootstrap"],
      link: 'https://ozbon.ng/'
    },
    {
      titleCard: 'COACH ANALYSIS',
      subCard: 'Coach Analysis is a platform for the sports industry where Coaches/Clubs can sign up to make digital communication with their teams and individual players. Mentoring the players using various features provided by the platform i.e., Videos, Highlights, Action logs, Comments/Feedback. App developed using Next.js and TypeScript, with Laravel 10 powering backend functionalities. ',
      titleModal: 'COACH ANALYSIS.',
      subModal:
        'Coach Analysis is a platform for the sports industry where Coaches/Clubs can sign up to make digital communication with their teams and individual players. Mentoring the players using various features provided by the platform i.e., Videos, Highlights, Action logs, Comments/Feedback. App developed using Next.js and TypeScript, with Laravel 10 powering backend functionalities.',
      subModal2:
        'Leveraging AWS S3 for seamless storage, Payments are managed by Stripe, It enables efficient video streaming, analysis, and insightful reporting for coaches and their teams. ',
        img: '/images/pro4.png',
        images : [
          { img: "/images/c1.png", title: "Dashboard" },
          { img: "/images/c2.png", title: "Manage Videos" },
          { img: "/images/c3.png", title: "Categories" },
          { img: "/images/c4.png", title: "Action Log" },
          { img: "/images/c5.png", title: "Manage Teams" },
          { img: "/images/c6.png", title: "Manage Highlights" },
          { img: "/images/c7.png", title: "Upload/Add Video" },
          { img: "/images/c8.png", title: "Manage Profiles" },
          { img: "/images/c9.png", title: "Payments" },
          { img: "/images/c10.png", title: "UPDATE" },
      
        ],
        skill:["Next.js",
          "TypeScript",
          "Laravel",'AWS S3','Stripe'],
      link: ''
    },
    {
      titleCard: 'COACH ANALYSIS 2',
      subCard: 'Coach Analysis is a platform for the sports industry where Coaches/Clubs can sign up to make digital communication with their teams and individual players. Mentoring the players using various features provided by the platform i.e., Videos, Highlights, Action logs, Comments/Feedback. App developed using Next.js and TypeScript, with Laravel 10 powering backend functionalities. ',
      titleModal: 'COACH ANALYSIS.',
      subModal:
        'Coach Analysis is a platform for the sports industry where Coaches/Clubs can sign up to make digital communication with their teams and individual players. Mentoring the players using various features provided by the platform i.e., Videos, Highlights, Action logs, Comments/Feedback. App developed using Next.js and TypeScript, with Laravel 10 powering backend functionalities.',
      subModal2:
        'Leveraging AWS S3 for seamless storage, Payments are managed by Stripe, It enables efficient video streaming, analysis, and insightful reporting for coaches and their teams. ',
        img: '/images/pro4.png',
        images : [
          { img: "/images/c1.png", title: "Dashboard" },
          { img: "/images/c2.png", title: "Manage Videos" },
          { img: "/images/c3.png", title: "Categories" },
          { img: "/images/c4.png", title: "Action Log" },
          { img: "/images/c5.png", title: "Manage Teams" },
          { img: "/images/c6.png", title: "Manage Highlights" },
          { img: "/images/c7.png", title: "Upload/Add Video" },
          { img: "/images/c8.png", title: "Manage Profiles" },
          { img: "/images/c9.png", title: "Payments" },
          { img: "/images/c10.png", title: "UPDATE" },
      
        ],
        skill:["Next.js",
          "TypeScript",
          "Laravel",'AWS S3','Stripe'],
      link: ''
    },
   
   
  ]
  