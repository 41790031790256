import React, { useState, useEffect } from 'react'
import { PopupModal } from 'react-calendly'

const BookCallModal = ({isOpen, setisOpen}) => {
    

  // Create a state to hold the rootElement
  const [rootElem, setRootElem] = useState(null)

  useEffect(() => {
    // Set the root element only on the client side after initial render
    setRootElem(document.body)
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      <PopupModal
        url="https://calendly.com/zeeshanbutt223/30min"
        onModalClose={() => setisOpen(false)}
        open={isOpen}
        rootElement={rootElem}
      />
    </div>
  )
}

export default BookCallModal
